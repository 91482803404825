/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {number} value
 * @returns {string}
 */
export function formatDate(value) {// 时间戳转换日期格式方法
  if (value == null) {
    return '';
  } else {
    let date = new Date(value);
    let y = date.getFullYear();// 年
    let MM = date.getMonth() + 1;// 月
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();// 日
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();// 时
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();// 分
    m = m < 10 ? ('0' + m) : m;
    let s = date.getSeconds();// 秒
    s = s < 10 ? ('0' + s) : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
  }
}


/**
 * @param {number} value
 * @returns {string}
 */
export function formatDay(value) {// 时间戳转换日期格式方法
  if (value == null) {
    return '';
  } else {
    let date = new Date(value);
    let y = date.getFullYear();// 年
    let MM = date.getMonth() + 1;// 月
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();// 日
    d = d < 10 ? ('0' + d) : d;
    return y + '年' + MM + '月' + d + '日'
  }
}

/**
 * @param {number} value
 * @returns {string}
 */
export function formatDay2(value) {// 时间戳转换日期格式方法
  if (value == null) {
    return '';
  } else {
    let date = new Date(value);
    let y = date.getFullYear();// 年
    let MM = date.getMonth() + 1;// 月
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();// 日
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + MM + '-' + d
  }
}


export function price(num, type) {

  // preferentialRetailPrice 特惠会员价
  // preferentialTradePrice 特惠店长价

  // retailPrice 会员价
  // tradePrice 店长价
  console.log(num);
  console.log(type);
  let now = new Date();
  let m = now.getTime();
  if (num.preferentialEndTime === null) {
    //没有特惠时间
    if (type === 'PURCHASE') {
      return num.tradePrice.toFixed(2); //没有小数点,返回两位小数
    } else {
      return num.retailPrice.toFixed(2);
    }

  }
  if (num.preferentialStartTime < m) {
    if (type === 'PURCHASE') {
      return num.preferentialTradePrice.toFixed(2);
    } else {
      return num.preferentialRetailPrice.toFixed(2);
    }
  }
  if (num.preferentialEndTime < m) {
    if (type === 'PURCHASE') {
      return num.tradePrice.toFixed(2);
    } else {
      return num.retailPrice.toFixed(2);
    }
  }
}

/**
 * @param {object} value
 * @param {string} field
 * @returns {number}
 */
export function preferential(value, field) {
  let now = new Date();
  let m = now.getTime();
  if (value.preferentialStartTime === null) {
    if (field === 'retailPrice') {
      return value.retailPrice.toFixed(2);
    } else if (field === 'tradePrice') {
      return value.tradePrice.toFixed(2);
    }
  }

  // console.log(new Date(value.preferentialStartTime).toLocaleString());
  // console.log(new Date(m).toLocaleString());
  // console.log(new Date(value.preferentialEndTime).toLocaleString());

  if (value.preferentialStartTime < m && m < value.preferentialEndTime) {
    //优惠中
    if (field === 'retailPrice') {
      return value.preferentialRetailPrice.toFixed(2);
    } else if (field === 'tradePrice') {
      return value.preferentialTradePrice.toFixed(2);
    }
  } else {
    //无优惠
    if (field === 'retailPrice') {
      return value.retailPrice.toFixed(2);
    } else if (field === 'tradePrice') {
      return value.tradePrice.toFixed(2);
    }
  }
}
/**
 * @param {string,number} path
 * @returns {string}
 */
export function decimals(num) {
  if (num === null) {
    return "-";
  } else if (num == 0) {
    return num;
  } else if (String(num).indexOf(".")) {
    // return num;
    return num.toFixed(2);
  } else {
    return num;
  }
}



/**
 * @param {number} value
 * @returns {string}
 */
export function countDown(value) {
  if (value == null) {
    return '';
  } else {
    // console.log(value );
    let EndTime = new Date(value); //截止时间 
    let NowTime = new Date();

    // console.log(NowTime.getTime() - EndTime.getTime());

    let t = 432000000 - (NowTime.getTime() - EndTime.getTime());
    let d = Math.floor(t / 1000 / 60 / 60 / 24);
    let h = Math.floor(t / 1000 / 60 / 60 % 24);
    let m = Math.floor(t / 1000 / 60 % 60);
    let s = Math.floor(t / 1000 % 60);
    h = h < 10 ? ('0' + h) : h;
    m = m < 10 ? ('0' + m) : m;
    s = s < 10 ? ('0' + s) : s;

    return `${d}天${h}小时${m}分${s}秒`;

  }
}

/**
 * @param {Array}
 * @returns {Array}
 */
export function getclassificationArray(list) {
  let arr = []
  if (list.rank === 1) {
    //点击一类
    arr.push(list.categoryId);
    if (list.subItemList) {
      for (let i = 0; i < list.subItemList.length; i++) {
        arr.push(list.subItemList[i].categoryId);
        if (list.subItemList[i].subItemList) {
          for (let n = 0; n < list.subItemList[i].subItemList.length; n++) {
            arr.push(list.subItemList[i].subItemList[n].categoryId);
          }
        }
      }
    }
  } else if (list.rank === 2) {
    //二类
    arr.push(list.categoryId);
    if (list.subItemList) {
      for (let n = 0; n < list.subItemList.length; n++) {
        arr.push(list.subItemList[n].categoryId);
      }
    }
  } else {
    arr.push(list.categoryId);
  }
  return arr
}