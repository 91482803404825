import store from './store'
import category from './category'
import brand from './brand'
import news from './news'
import personalCenter from './personalCenter'
import pay from './pay'

const routes = [
	{
		path: '/Login',
		name: 'login',
		component: () => import(/* webpackChunkName: 'Login' */'@/views/Login'),
		// redirect: '',
		children: [
			{
				path: 'Register',
				name: 'register',
				component: () => import(/* Register: 'Register' */'@/views/Login/Register'),

			}, {
				path: 'ToRegister',
				name: 'to-register',
				component: () => import(/* Register: 'Register' */'@/views/Login/Register'),
			}, {
				path: 'ForgetPassword',
				name: 'forget-password',
				component: () => import(/* Register: 'ForgetPassword' */'@/views/Login/ForgetPassword'),
			}, {
				path: 'PerfectInformation',
				name: 'perfect-information',
				component: () => import(/* Register: 'PerfectInformation' */'@/views/Login/PerfectInformation'),
				children: [
					{
						path: 'Audit',
						name: 'audit',
						component: () => import(/* Register: 'Audit' */'@/views/Login/PerfectInformation'),

					}]
			},
			{
				path: 'AuditTips',
				name: 'audit-tips',
				component: () => import(/* Register: 'Audit' */'@/views/Login/AuditTips')
			}
		]
	},
	{
		path: '/',
		component: () => import(/* webpackChunkName: '' */'@/layout'),
		meta: { name: '首页' },
		redirect: '/Home',
		children: [
			{
				path: 'Home',
				name: 'home',
				component: () => import(/* webpackChunkName:'home' */'@/views/Home'),
			},
			store,//店铺
			category,//类目
			brand,//品牌
			news,//新闻
			personalCenter,//个人中心
			pay,//支付
			{
				path: '/announcement',
				name: 'announcement',
				component: () => import(/* webpackChunkName: 'Agreement' */'@/views/announcement'),
			},
			{
				path: 'TrackBack',
				name: 'track-back',
				component: () => import(/* webpackChunkName:'TrackBack' */'@/views/TrackBack'),
			},
			{
				path: 'AllActivities',
				name: 'all-activities',
				component: () => import(/* webpackChunkName:'AllActivities' */'@/views/Activity'),
			},
			{
				path: 'ActivitiesColumn/:id',
				name: 'activities-column',
				component: () => import(/* webpackChunkName:'ActivitiesColumn' */'@/views/Activity/ActivitiesColumn'),
			},
			{
				path: 'StoreList',
				name: 'store-list',
				meta: { name: '关联店铺' },
				component: () => import(/* webpackChunkName:'StoreList' */'@/views/StoreList'),
			},
			{
				path: 'AllGoods',
				name: 'all-goods',
				meta: { name: '全部商品' },
				component: () => import(/* webpackChunkName:'AllGoods' */'@/views/AllGoods')
			},
			{
				//购物车
				path: 'ShoppingCart',
				name: 'shopping-cart',
				component: () => import(/* webpackChunkName:'ShoppingCart' */'@/views/ShoppingCart'),
			},
			{
				//订单
				path: 'ConfirmAnOrder',
				name: 'confirm-an-rder',
				component: () => import(/* webpackChunkName:'ConfirmAnOrder' */'@/views/ConfirmAnOrder'),
			},
			{
				path: 'Logistics',
				name: 'logistics',
				component: () => import(/* webpackChunkName:'Logistics' */'@/views/Logistics'),
			},
			{
				path: 'BankingServices',
				name: 'banking-services',
				component: () => import(/* webpackChunkName:'BankingServices' */'@/views/BankingServices'),
			},
			{
				path: 'InsuranceServices',
				name: 'insurance-services',
				component: () => import(/* webpackChunkName:'InsuranceServices' */'@/views/InsuranceServices'),
			},
			{
				path: 'AboutUs',
				name: 'about-us',
				component: () => import(/* webpackChunkName:'AboutUs' */'@/views/AboutUs'),
			},
			{
				//数字权益
				path: 'DigitalRights',
				name: 'digital-tights',
				component: () => import(/* webpackChunkName:'DigitalRights' */'@/views/DigitalRights'),
				children: [{
					path: '',
					name: 'mall',
					component: () => import(/* webpackChunkName:'Mall' */'@/views/DigitalRights/Mall'),
				}, {
					path: 'MallDetail',
					name: 'mall-detail',
					component: () => import(/* webpackChunkName:'Mall' */'@/views/DigitalRights/MallDetail'),
				},]
			},
			{
				path: 'Test',
				name: 'test',
				component: () => import(/* webpackChunkName:'Test' */'@/views/Test'),
			},
		]
	},
	{
		path: '/Agreement/:name?',
		name: 'agreement',
		component: () => import(/* webpackChunkName: 'Agreement' */'@/views/Agreement'),
	},

]

export default routes
