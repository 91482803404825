import View from './View'

const brand =
{
  path: 'Brand', //品牌
  name: 'brand',
  redirect: 'Brand/AllBrand',
  component: View,
  children: [
    {
      path: 'AllBrand',
      name: 'all-brand',
      meta: { name: '全部品牌' },
      component: () => import(/* webpackChunkName:'Brand' */'@/views/Brand/AllBrand'),
    },
    {
      path: 'RecommendedBrand',
      name: 'recommended-brand',
      meta: { name: '推荐品牌' },
      component: () => import(/* webpackChunkName:'RecommendedBrand' */'@/views/Brand/RecommendedBrand'),
    },
    {
      path: 'BrandGoods',
      name: 'brand-goods',
      component: () => import(/* webpackChunkName:'BrandGoods' */'@/views/Brand/BrandGoods'),
    }
  ]
}

export default brand

