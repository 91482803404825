import request from '@/utils/userRequest'
import request2 from '@/utils/request'


//获取当前用户信息
export async function getUser(data) {
    return await request({
        url: '/consumer-user-server/User/getCurrUser',
        method: 'post',
        data
    })
}

//根据id获取用户信息
export async function getUserData(data) {
    return await request({
        url: '/consumer-user-server/User/findUserById',
        method: 'post',
        data
    })
}

//更新用户
export async function updateUser(data) {
    return await request({
        url: '/consumer-user-server/User/updateUser',
        method: 'post',
        data
    })
}

//修改为团长
export async function updateUserType(data) {
    return await request({
        url: '/consumer-user-server/User/updateUserType',
        method: 'post',
        data
    })
}

//验证密码
export async function matchePassword(data) {
    return await request({
        url: '/consumer-user-server/User/matchePassword',
        method: 'post',
        data
    })
}

//手机号修改密码
export async function updatePasswdByUserName(data) {
    return await request({
        url: '/consumer-user-server/User/updatePasswdByUserName',
        method: 'post',
        data
    })
}


//发送验证码
export async function sendCAPTCHA(data) {
    return await request2({
        url: '/external-server/Message/sendCAPTCHA',
        method: 'post',
        data
    })
}

//验证验证码
export async function verificationCAPTCHA(data) {
    return await request2({
        url: '/external-server/Message/verificationCAPTCHA',
        method: 'post',
        data
    })
}
















//购物车列表
export async function getShoppingCartList(data) {
    return await request({
        url: '/consumer-shopping-server/Order/OrderCartList',
        method: 'post',
        data
    })
}
//购物车总数
export async function getShoppingCartSum(data) {
    return await request({
        url: '/consumer-shopping-server/Order/OrderCartTotal',
        method: 'post',
        data
    })
}

//添加购物车
export async function InsertCusOrderCart(data) {
    return await request({
        url: '/consumer-shopping-server/Order/InsertCusOrderCart',
        method: 'post',
        data
    })
}

//删除购物车
export async function DeleteOrderCart(data) {
    return await request({
        url: '/consumer-shopping-server/Order/DeleteOrderCart',
        method: 'post',
        data
    })
}

//修改购物车数量
export async function UpdateOrderCart(data) {
    return await request({
        url: '/consumer-shopping-server/Order/UpdateOrderCart',
        method: 'post',
        data
    })
}



//形成订单
export async function TransactionOrder(data) {
    return await request({
        url: '/consumer-shopping-server/Order/TransactionOrder',
        method: 'post',
        data
    })
}

//已完成订单
export async function ClosedOrderList(data) {
    return await request({
        url: '/consumer-shopping-server/Order/ClosedOrderList',
        method: 'post',
        data
    })
}

//已完成订单总数
export async function ClosedOrderTotal(data) {
    return await request({
        url: '/consumer-shopping-server/Order/ClosedOrderTotal',
        method: 'post',
        data
    })
}

//订单详情
export async function DealDetails(data) {
    return await request({
        url: '/consumer-shopping-server/Order/DealDetails',
        method: 'post',
        data
    })
}

//总订单详情
export async function TotalDealDetails(data) {
    return await request({
        url: '/consumer-shopping-server/Order/TotalDealDetails',
        method: 'post',
        data
    })
}
//取消订单
export async function CancelOrder(data) {
    return await request({
        url: '/consumer-shopping-server/Order/CancelOrder',
        method: 'post',
        data
    })
}

//删除订单
export async function DeleteOrder(data) {
    return await request({
        url: '/consumer-shopping-server/Order/DeleteOrder',
        method: 'post',
        data
    })
}

//修改订单状态
export async function UpdateDealStatus(data) {
    return await request({
        url: '/consumer-shopping-server/Order/UpdateDealStatus',
        method: 'post',
        data
    })
}

//订单列表数量
export async function OrderStatusQuantity(data) {
    return await request({
        url: '/consumer-shopping-server/Order/OrderStatusQuantity',
        method: 'post',
        data
    })
}







//运单详情
export async function WaybillDetails(data) {
    return await request2({
        url: '/external-server/Waybill/queryRoute',
        method: 'post',
        data
    })
}







//新增收货地址
export async function insertAddress(data) {
    return await request({
        url: '/consumer-user-server/Address/insertAddress',
        method: 'post',
        data
    })
}
//查询收货地址
export async function queryAddress(data) {
    return await request({
        url: '/consumer-user-server/Address/queryAddress',
        method: 'post',
        data
    })
}
//修改地址
export async function updateAddress(data) {
    return await request({
        url: '/consumer-user-server/Address/updateAddress',
        method: 'post',
        data
    })
}

//删除地址
export async function deleteAddress(data) {
    return await request({
        url: '/consumer-user-server/Address/deleteAddress',
        method: 'post',
        data
    })
}
