import View from './View'

const category =
{
	path: 'Category', // 类目页面
	name: 'category',
	redirect: 'Category/AllCtegories',
	component: View,
	children: [
		{
			path: 'AllCtegories',
			name: 'all-ctegories',
			meta: { name: '全部类目' },
			component: () => import(/* webpackChunkName:'AllCategories' */'@/views/Category/AllCategories'),
		}, {
			path: 'CategoryList',
			name: 'category-list',
			meta: { name: '类目商品' },
			component: () => import(/* webpackChunkName:'CategoryList' */'@/views/Category/CategoryList'),
		}
	]
}
export default category