import View from './View'

const personalCenter =
{
  //c端个人中心
  path: 'PersonalCenter',
  name: 'personal-center',
  component: () => import(/* webpackChunkName:'PersonalCenter' */'@/views/PersonalCenter'),
  redirect: 'PersonalCenter/OrderManagement/AllOrders',
  children: [
    {
      //订单管理
      path: 'OrderManagement',
      redirect: 'OrderManagement/AllOrders',
      component: () => import('@/views/PersonalCenter/OrderManagement/AllOrders'),
      children: [
        {
          //全部订单
          path: 'AllOrders',
          name: 'all-orders',
          component: () => import(/* webpackChunkName:'AllOrders' */'@/views/PersonalCenter/OrderManagement/AllOrders'),
        }, {
          //待付款订单
          path: 'OrderPendingPayment',
          name: 'order-pending-payment',
          component: () => import(/* webpackChunkName:'OrderPendingPayment' */'@/views/PersonalCenter/OrderManagement/AllOrders'),
        }, {
          //待发货订单
          path: 'BacklogOrder',
          name: 'backlog-order',
          component: () => import(/* webpackChunkName:'AllOrders' */'@/views/PersonalCenter/OrderManagement/AllOrders'),
        }, {
          //待收货订单
          path: 'OrderToBeReceived',
          name: 'order-to-be-received',
          component: () => import(/* webpackChunkName:'OrderToBeReceived' */'@/views/PersonalCenter/OrderManagement/AllOrders'),
        }, {
          //已完成
          path: 'Finished',
          name: 'finished',
          component: () => import(/* */'@/views/PersonalCenter/OrderManagement/AllOrders')
        }
      ]
    },
    {
      //退货/售后
      path: 'AfterSales',
      name: 'after-sales',
      component: () => import(/* webpackChunkName:'AfterSales' */'@/views/PersonalCenter/AfterSales'),
    },
    {
      //收货地址
      path: 'DeliveryAddress',
      name: 'delivery-address',
      component: () => import(/* webpackChunkName:'DeliveryAddress' */'@/views/PersonalCenter/DeliveryAddress'),
    },
    {
      //专票抬头
      path: 'Invoices',
      name: 'invoices',
      component: () => import(/* webpackChunkName:'Invoices' */'@/views/PersonalCenter/Invoices'),
    },
    {
      //用户资料
      path: 'UserData',
      name: 'user-data',
      redirect: 'UserData/DataPage',
      component: () => import(/* webpackChunkName:'UserData' */'@/views/PersonalCenter/UserData/index'),
      children: [{
        path: 'DataPage',
        name: 'data-page',
        component: () => import(/* webpackChunkName:'DataPage' */'@/views/PersonalCenter/UserData/DataPage'),
      }, {
        path: 'PhoneNumber',
        name: 'phone-number',
        component: () => import(/* webpackChunkName:'PhoneNumber' */'@/views/PersonalCenter/UserData/PhoneNumber'),
      }, {
        path: 'ChangePassword',
        name: 'change-password',
        component: () => import(/* webpackChunkName:'ChangePassword' */'@/views/PersonalCenter/UserData/ChangePassword'),
      }]
    }, {
      //收货地址
      path: 'ShippingAddress',
      name: 'shipping-address',
      component: () => import(/* webpackChunkName:'ShippingAddress' */'@/views/PersonalCenter/ShippingAddress'),
    }, {
      //订单详情
      path: 'OrderDetails',
      name: 'order-details',
      component: () => import(/* webpackChunkName:'OrderDetails' */'@/views/PersonalCenter/OrderManagement/OrderDetails'),
    }, 
  ]
}

export default personalCenter

