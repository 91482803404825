
const store =
{
  path: 'Store', //店铺页面
  name: 'store',
  redirect: '/Store/HomePage/:id',
  component: () => import(/* webpackChunkName:'store' */'@/views/Store'),
  children: [
    {
      path: 'HomePage/:id',
      name: 'home-page',
      meta: { name: '店铺首页' },
      component: () => import(/* webpackChunkName:'HomePage' */'@/views/Store/HomePage'),
    },
    {
      path: 'HomePage/:id/GoodsDetails/:barcode?',
      name: 'goodsd-etails',
      meta: { name: '商品详情' },
      component: () => import(/* webpackChunkName:'GoodsDetails' */'@/views/Store/GoodsDetails'),
    },
    {
      path: 'HomePage/:id/StoreActivity/:activityId',
      name: 'store-activity',
      meta: { name: '店铺活动' },
      component: () => import(/* webpackChunkName:'StoreActivity' */'@/views/Store/StoreActivity'),
    }, {
      path: 'HomePage/:id/StoreCategory',
      name: 'store-category',
      meta: { name: '店铺类目' },
      component: () => import(/* webpackChunkName:'StoreCategory' */'@/views/Store/StoreCategory'),
    },
  ]
}

export default store