import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes/index'
Vue.use(VueRouter)


import Cookies from "js-cookie";
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  
  // if (Cookies.get("token_personal") || Cookies.get("KAI_HUA_ADMIN_TOKEN") != undefined) {
  // } else {
  //   window.location.href = process.env.VUE_APP_URL_API
  // }

  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  next();

})
export default router
