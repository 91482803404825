import View from './View'

const news =
{
  path: 'News',
  name: 'news',
  redirect: 'News/NewsList',
  component: View,
  children: [
    {
      path: 'NewsList',
      name: 'news-list',
      component: () => import(/* webpackChunkName:'NewsList' */'@/views/News/NewsList'),
    },
    {
      path: 'NewsDetails/:newsCode',
      name: 'news-details',
      component: () => import(/* webpackChunkName:'NewsDetails' */'@/views/News/NewsDetails'),
    }
  ]
}

export default news

