import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueLazyLoad from 'vue-lazyload'

import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/element-variables.scss'

import '@/icons'
// import '@/Family/font.css'

import * as API from './api'

import WS from '@/utils/websocket'
Vue.prototype.$ws = WS


import '@/styles/reset.css'
Vue.use(ElementUI);

Vue.prototype.$API = API
Vue.prototype.$URL = process.env.VUE_APP_URL_API


Vue.config.productionTip = false
// Vue.config.productionTip = process.env.VUE_APP_MODE === "production";
Vue.use(VueLazyLoad, {
  preLoad: 1.2,
  error: require('./assets/images/error.png'),
  // loading: require('./assets/images/loading.gif'),
  attempt: 2,
})

const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vue
// router.afterEach((to, from) => {
//   vm.$el.scrollTop = 0;
// })