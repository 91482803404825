import { getUser, getShoppingCartSum, getUserData } from "@/api/PersonalUser"
import _this from '@/main.js'

export default {
	namespaced: true,
	state: {
		userId: "", //消费者id
		userInfo: { userEntity: { name: null }, userDetailEntity: { profilePicture: null } }, //消费者信息
		userLogin: false,  //消费者登录
		userSum: 0 // 商品数量
	},
	mutations: {
		setUserId(state, payload) {
			let id = sessionStorage.getItem('userId')
			if (!id) {
				sessionStorage.setItem("userId", payload.id)
				if (payload.userInfo.userEntity.userType==='PURCHASE'&&!payload.userInfo.userDetailEntity.getGoodsName) {
					_this.$alert("您的店长资料尚未完善，请完善并提交资料审核。", "提示", {
						confirmButtonText: "立即完善资料",
						dangerouslyUseHTMLString: true,

					}).then(() => {
						_this.$router.push("/Login/PerfectInformation");
					}).catch(() => {
						_this.$router.push("/Login/PerfectInformation");

					});
				}
			}
			sessionStorage.setItem("userType", payload.userInfo.userEntity.userType)
			// if (document.getElementById("home")) {

			// 	if (payload.userInfo.userEntity.userType === 'CONSUMER') {
			// 		_this.$confirm('您还不是店长身份是否去成为店长？', '提示', {
			// 			confirmButtonText: '确定',
			// 			cancelButtonText: '取消',
			// 			type: 'warning'
			// 		}).then(() => {
			// 			_this.$router.push("/Login/ToRegister");
			// 		}).catch(() => {

			// 		});
			// 	}

			// }
			state.userId = payload.id
			state.userLogin = true
		},
		//用户信息
		setUser(state, payload) {
			state.userInfo = payload
		},
		getCartSum(state, payload) {
			state.userSum = payload
		},
	},
	actions: {
		async getUserInfo(ctx, addValue) {
			let user = await getUser({});
			ctx.commit("setUserId", user.data.data);
			let userInfo = await getUserData({
				object: {
					id: ctx.state.userId,
				},
			});
			ctx.commit("setUser", userInfo.data.data.userInfo)
		},

		getShoppingCartSum(ctx, addValue) {
			let a = setTimeout(async () => {
				let shop = await getShoppingCartSum({
					object: {
						userId: ctx.state.userId,
					},
					pageNum: 0,
					pageSize: 0,
				});
				shop.data.data && ctx.commit("getCartSum", shop.data.data.sum)
				clearTimeout(a)
			}, 200);
		}


	}

}
