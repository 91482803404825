
import request from '@/utils/userRequest'

//登录
export async function login(data) {
    return await request({
        url: '/login',
        method: 'post',
        data
    })
}

//注册 
export async function register(data) {
    return await request({
        url: '/consumer-user-server/User/registerUser',
        method: 'post',
        data
    })
}