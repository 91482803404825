
export default {
    namespaced: true,
    state: {
        webSocketMsg: '',
        webSocketConnect: false,
    },
    mutations: {
        // 存储socket推送消息
        SET_WS_MSG(state, msg) {
            state.webSocketMsg = msg
        },
        // 设置socket链接状态
        SET_WS_CONNECT(state, msg) {
            state.webSocketConnect = msg
        }

    },
    actions: {

    },
    getters: {
        webSocketConnect: state => state.webSocketConnect,
        webSocketMsg: state => state.webSocketMsg
    }

}
