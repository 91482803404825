<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import * as sysTool from "@/assets/js/systemTool";
import Cookies from "js-cookie";

export default {
  name: "app",
  data() {
    return {
      ip: "",
      area: "",
      brower: "",
      os: "",
    };
  },
  created() {
    this.ip = sessionStorage.getItem("ip");
    this.area = sessionStorage.getItem("area");
    this.brower = sysTool.GetCurrentBrowser();
    this.os = sysTool.GetOs();
    console.log(this.ip, this.area, this.brower);
    localStorage.setItem("Login", false);
    Cookies.get("token_personal") && localStorage.setItem("Login", true);
    // console.log(sessionStorage.getItem("ip"));

    this.$nextTick(() => {
      // 禁用右键
      // document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      // document.onselectstart = new Function("event.returnValue=false");
      //禁止f12
      // document.onkeydown = new Function("event.returnValue=false");
    });

    // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
    // document.onkeydown = function (e) {
    //   if (e && e.keyCode === 123) {
    //     e.returnValue = false;
    //     // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
    //     return false;
    //   }
    // };
  },
  beforeDestroy() {
    // localStorage.removeItem("category");
  },
};
</script>

<style lang="scss" scoped>
#app {
  // height: 100%;
  background: #f5f5f5;
  overflow-x: hidden;
  font-family: Microsoft YaHei;
}
</style>