
const pay =
{
  //支付
  path: 'Pay',
  name: 'pay',
  component: () => import(/* webpackChunkName:'Pay' */'@/views/Pay'),
  redirect: 'Pay/ModeOfPayment',
  children: [
    {
      path: 'ModeOfPayment',
      name: 'mode-of-payment',
      component: () => import(/* webpackChunkName:'ModeOfPayment' */'@/views/Pay/ModeOfPayment'),
    },
    {
      path: 'Cashier',
      name: 'cashier',
      component: () => import(/* webpackChunkName:'Cashier' */'@/views/Pay/Cashier'),
    },
    {
      path: 'CompletionOfPayment',
      name: 'completion-of-payment',
      component: () => import(/* webpackChunkName:'CompletionOfPayment' */'@/views/Pay/CompletionOfPayment'),
    },
    {
      path: 'NoneOrder',
      name: 'none-order',
      component: () => import(/* webpackChunkName:'NoneOrder' */'@/views/Pay/NoneOrder'),
    }
  ]
}

export default pay

