import axios from 'axios'
import Cookies from "js-cookie";
import store from '@/store';


// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url 
    //  20003 网关
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

service.interceptors.request.use(config => {
    // config.headers['Region'] = "SG"
    config.headers['Region'] = "QG"
    
    let token = Cookies.get("KAI_HUA_ADMIN_TOKEN");
    token && (config.headers.common['Authorization'] = `Bearer ${token}`)
    token && (config.headers['Operator-Identity-Id'] = store.state.user.id)

    return config
},
    err => {
        console.log(err)
        return Promise.reject(err)
    });

export default service
